<template>
  <div>
    <b-alert
      show
      variant="light"
      class="alert alert-custom alert-white alert-shadow fade show gutter-b"
    >
      <div class="alert-icon">
        <span class="svg-icon svg-icon-lg">
          <inline-svg src="/media/svg/icons/Tools/Compass.svg" />
        </span>
      </div>
      <div class="alert-text">
        Berikut adalah <b>Stok Obat</b> yang ada di Klinik IONA
      </div>
      <b-button
        squared
        variant="success"
        v-if="manipulateBtn == true"
        @click="$router.push('/medicine/maintain-stock-add')"
        >Tambah</b-button
      >
    </b-alert>

    <div class="row">
      <div class="col-md-12">
        <Card>
          <template v-slot:body>
            <!-- Filter -->
            <div class="row justify-content-end mt-n3">
              <div class="col-md-9">
                <button
                  class="btn mx-1 btn-timeseries"
                  @click="btnActiveOnClick('days')"
                  v-bind:class="{
                    'btn-info': btnActive.days,
                    'btn-outline-info': btnActive.days == false,
                  }"
                >
                  Hari Ini
                </button>
                <button
                  class="btn mx-1 btn-timeseries"
                  @click="btnActiveOnClick('month')"
                  v-bind:class="{
                    'btn-info': btnActive.month,
                    'btn-outline-info': btnActive.month == false,
                  }"
                >
                  Bulan Ini
                </button>
                <button
                  class="btn mx-1 btn-timeseries"
                  @click="btnActiveOnClick('lastMonth')"
                  v-bind:class="{
                    'btn-info': btnActive.lastMonth,
                    'btn-outline-info': btnActive.lastMonth == false,
                  }"
                >
                  Bulan Kemarin
                </button>
                <button
                  class="btn mx-1 btn-timeseries"
                  @click="btnActiveOnClick('year')"
                  v-bind:class="{
                    'btn-info': btnActive.year,
                    'btn-outline-info': btnActive.year == false,
                  }"
                >
                  Tahun Ini
                </button>
                <button
                  class="btn mx-1 btn-timeseries"
                  v-bind:class="{
                    'btn-info': btnActive.period,
                    'btn-outline-info': btnActive.period == false,
                  }"
                  @click="btnPeriodeOnClick"
                >
                  Periode
                </button>
              </div>
              <div class="col-md-3 col-12">
                <b-input-group>
                  <b-form-input
                    type="text"
                    v-model="filter.medicine_name"
                    placeholder="Saring Nama Obat"
                    @keyup="filterByName"
                  ></b-form-input>
                </b-input-group>
              </div>
            </div>

            <!-- timeseries filter -->
            <div class="row mt-3">
              <div class="col-md-9" v-if="onShowPeriod">
                <span class="d-none d-sm-block">
                  <b-input-group>
                    <b-form-input
                      v-model="filter.start_date"
                      type="text"
                      placeholder="Awal"
                      autocomplete="off"
                      readonly
                    ></b-form-input>
                    <b-input-group-append>
                      <b-form-datepicker
                        v-model="filter.start_date"
                        button-only
                        right
                        reset-button
                        locale="id"
                        aria-controls="example-input"
                      ></b-form-datepicker>
                    </b-input-group-append>
                    <b-button squared variant="light">s/d</b-button>
                    <b-form-input
                      v-model="filter.end_date"
                      type="text"
                      placeholder="Akhir"
                      autocomplete="off"
                      readonly
                    ></b-form-input>
                    <b-input-group-append>
                      <b-form-datepicker
                        v-model="filter.end_date"
                        button-only
                        right
                        reset-button
                        locale="id"
                        aria-controls="example-input"
                      ></b-form-datepicker>
                    </b-input-group-append>
                    <template #append>
                      <b-button squared @click="pagination" variant="success"
                        >Cari</b-button
                      >
                      <b-button squared @click="resetFilter" variant="danger"
                        >Reset</b-button
                      >
                    </template>
                  </b-input-group>
                </span>
                <span class="d-block d-sm-none">
                  <div class="row">
                    <div class="col-12 pb-1">
                      <b-input-group>
                        <b-form-input
                          v-model="filter.start_date"
                          type="text"
                          placeholder="Awal"
                          autocomplete="off"
                          readonly
                        ></b-form-input>
                        <b-input-group-append>
                          <b-form-datepicker
                            v-model="filter.start_date"
                            button-only
                            right
                            reset-button
                            locale="id"
                            aria-controls="example-input"
                          ></b-form-datepicker>
                        </b-input-group-append>
                        <template #append>
                          <b-button squared @click="pagination" variant="success"
                            >Cari</b-button
                          >
                          <b-button
                            squared
                            @click="resetFilter"
                            variant="danger"
                            >Reset</b-button
                          >
                        </template>
                      </b-input-group>
                    </div>
                    <div class="col-12 pt-0">
                      <b-input-group>
                        <b-button squared variant="light">s/d</b-button>
                        <b-form-input
                          v-model="filter.end_date"
                          type="text"
                          placeholder="Akhir"
                          autocomplete="off"
                          readonly
                        ></b-form-input>
                        <b-input-group-append>
                          <b-form-datepicker
                            v-model="filter.end_date"
                            button-only
                            right
                            reset-button
                            locale="id"
                            aria-controls="example-input"
                          ></b-form-datepicker>
                        </b-input-group-append>
                      </b-input-group>
                    </div>
                  </div>
                </span>
              </div>
            </div>

            <!-- Table -->
            <b-table
              striped
              hover
              class="mt-3"
              :items="items"
              :fields="fields"
              
              responsive="sm"
            >
              <template #table-colgroup="scope">
                <col
                  v-for="field in scope.fields"
                  :key="field.key"
                  :style="{ width: field.key === 'actions' ? '20%' : '' }"
                />
              </template>
              <template #cell(stock_in_out)="data">
                <i
                  v-if="data.item.stock_in_out == 0"
                  class="fas fa-arrow-down"
                  style="color: green"
                  v-b-tooltip.hover
                  title="Stok Masuk"
                ></i>
                <i
                  v-if="data.item.stock_in_out == 1"
                  class="fas fa-arrow-up"
                  style="color: red"
                  v-b-tooltip.hover
                  title="Stok Keluar"
                ></i>
              </template>
              <template #cell(price)="data">
                <span v-if="data.item.price == 0.0"></span>
                <span v-if="data.item.price != 0.0">{{ parseInt(data.item.price).toLocaleString('id-ID') }}</span>
              </template>
              <template #cell(actions)="data">
                <b-button
                  size="sm"
                  class="mr-1 btn-info"
                  v-b-tooltip.hover
                  title="Detail"
                  @click="
                    $router.push({
                      path: '/medicine/maintain-stock-detail/' + data.item.id,
                    })
                  "
                  ><i class="fas fa-eye px-0"></i
                ></b-button>
                <b-button
                  size="sm"
                  class="mr-1 btn-success"
                  v-b-tooltip.hover
                  title="Ubah"
                  @click="
                    $router.push({
                      path: '/medicine/maintain-stock-update/' + data.item.id,
                    })
                  "
                  ><i class="fas fa-edit px-0"></i
                ></b-button>
                <!-- <b-button
                  size="sm"
                  class="mr-1 btn-success"
                  v-b-tooltip.hover
                  title="Edit"
                  v-if="manipulateBtn == true"
                  @click="$router.push({path: '/medicine/maintain-stock-edit/' + data.item.id,})"
                ><i class="fas fa-edit px-0"></i></b-button> -->
                <b-button
                  size="sm"
                  class="mr-1"
                  variant="primary"
                  v-b-tooltip.hover
                  title="Lihat Batch"
                  v-if="data.item.stock_in_out == 0"
                  @click="
                    $router.push({
                      path: '/medicine/batch-stock-per-batch/' + data.item.id,
                    })
                  "
                  ><i class="fas fa-boxes px-0"></i
                ></b-button>
                <!-- <b-button
                  size="sm"
                  class="mr-1"
                  variant="primary"
                  v-b-tooltip.hover
                  title="Riwayat Batch"
                  v-if="data.item.stock_in_out == 1"
                  @click="showModal(data.item.medicine_id)"
                  ><i class="fas fa-boxes px-0"></i
                ></b-button> -->
              </template>
            </b-table>
            <b-pagination
              v-if="items.length != 0"
              v-model="currentPage"
              :total-rows="totalRows"
              :per-page="perPage"
              @page-click="pageOnClick"
              class="mt-4"
            ></b-pagination>
          </template>
        </Card>
      </div>
    </div>

    <!-- Modal Batch History -->
    <b-modal ref="my-modal" hide-footer title="Riwayat Batch" size="xl">
      <b-table
        striped
        hover
        class="mt-3"
        :items="items_history"
        :fields="fields_history"
      >
        <template #table-colgroup="scope">
          <col
            v-for="field in scope.fields"
            :key="field.key"
            :style="{ width: field.key === 'actions' ? '20%' : '' }"
          />
        </template>
        <template #cell(stock_in_out)="data">
          <i
            v-if="data.item.stock_in_out == 0"
            class="fas fa-arrow-down"
            style="color: green"
            v-b-tooltip.hover
            title="Stok Masuk"
          ></i>
          <i
            v-if="data.item.stock_in_out == 1"
            class="fas fa-arrow-up"
            style="color: red"
            v-b-tooltip.hover
            title="Stok Keluar"
          ></i>
        </template>
      </b-table>
      <b-pagination
        v-if="items.length != 0"
        v-model="currentPage"
        :total-rows="totalRows"
        :per-page="perPage"
        @page-click="pageOnClick"
        class="mt-4"
      ></b-pagination>
    </b-modal>
  </div>
</template>

<script>
import { SET_BREADCRUMB } from "@/core/services/store/breadcrumbs.module";
import Card from "@/view/content/Card.vue";
import module from "@/core/modules/CrudModule.js";
import moment from 'moment';
// import ApiService from "@/core/services/api.service"

export default {
  name: "List",

  components: {
    Card,
  },

  data() {
    return {
      // Filter
      filter: {
        medicine_name: "",
        start_date: moment().startOf('day').format('YYYY-MM-DD HH:mm:ss'),
        end_date: moment().endOf('day').format('YYYY-MM-DD HH:mm:ss')
      },
      // filter timeseries
      onShowPeriod: false,
      btnActive: {
        days : true,
        month : false,
        lastMonth : false,
        year : false,
        period : false,
      },
      // Pagination
      perPage: 20,
      currentPage: 1,
      totalRows: 1,
      // Table Head
      fields: [
        {
          key: "number",
          label: "#",
          sortable: true,
          thClass: "th-list-medicines",
          tdClass: "td-list-medicines",
        },
        {
          key: "medicine_name",
          label: "Obat",
          sortable: true,
          thClass: "th-list-medicines",
          tdClass: "td-list-medicines",
        },
        {
          key: "stock_in_out",
          label: "Jenis Stok",
          sortable: true,
          thClass: "th-list-medicines",
          tdClass: "td-list-medicines",
        },
        {
          key: "quantity",
          label: "Jml",
          sortable: true,
          thClass: "th-list-medicines",
          tdClass: "td-list-medicines",
        },
        {
          key: "remaining_stock",
          label: "Stok sisa",
          sortable: true,
          thClass: "th-list-medicines",
          tdClass: "td-list-medicines",
        },
        {
          key: "price",
          label: "Harga",
          sortable: true,
          thClass: "th-list-medicines",
          tdClass: "td-list-medicines",
        },
        {
          key: "vendor_name",
          label: "Penyedia",
          sortable: true,
          thClass: "th-list-medicines",
          tdClass: "td-list-medicines",
        },
        {
          key: "created_at_display",
          label: "Tgl. Input",
          sortable: true,
          thClass: "th-list-medicines",
          tdClass: "td-list-medicines",
        },
        {
          key: "created_by_name",
          label: "Penginput",
          sortable: true,
          thClass: "th-list-medicines",
          tdClass: "td-list-medicines",
        },
        {
          key: "notes",
          label: "Keterangan",
          sortable: true,
          thClass: "th-list-medicines",
          tdClass: "td-list-medicines",
        },

        { key: "actions", label: "Aksi" },
      ],
      // Table Head
      items: [],
      manipulateBtn: false,
      items_history: [],
      fields_history: [
        {
          key: "number",
          label: "#",
          sortable: true,
          thClass: "th-list-medicines",
          tdClass: "td-list-medicines",
        },
        {
          key: "stock_in_out",
          label: "Jenis Stok",
          sortable: true,
          thClass: "th-list-medicines",
          tdClass: "td-list-medicines",
        },
        {
          key: "medicine_name",
          label: "Obat",
          sortable: true,
          thClass: "th-list-medicines",
          tdClass: "td-list-medicines",
        },
        {
          key: "batch_no",
          label: "Batch No",
          sortable: true,
          thClass: "th-list-medicines",
          tdClass: "td-list-medicines",
        },
        {
          key: "quantity",
          label: "Kuantitas",
          sortable: true,
          thClass: "th-list-medicines",
          tdClass: "td-list-medicines",
        },
        {
          key: "remaining_stock",
          label: "Sisa Stok",
          sortable: true,
          thClass: "th-list-medicines",
          tdClass: "td-list-medicines",
        },
        // { key: "actions", label: "Aksi" },
      ],
    };
  },

  methods: {
    pageOnClick(evt, page) {
      evt.preventDefault();
      this.currentPage = page;
      this.pagination();
    },

    async pagination() {
      let filterParams = `&medicine_name=${this.filter.medicine_name}&start_date=${this.filter.start_date}&end_date=${this.filter.end_date}`;
      let response = await module.paginate(
        "medicine-stocks",
        `?page=${this.currentPage}&page_size=${this.perPage}${filterParams}`
      );
      let pagination = response.meta.pagination;
      this.totalRows = pagination.total;

      let a, b;
      b = this.perPage * (this.currentPage - 1) + 1;
      for (a = 0; a < response.data.length; a++) {
        response.data[a].number = b + a;
      }
      this.items = response.data;
    },

    filterByName() {
      this.pagination();
    },

    showModal(id) {
      this.$refs["my-modal"].show();
      this.paginationHistory(id);
    },

    async paginationHistory(id) {
      let response = await module.paginate(
        "medicine-batch-histories/get-by-medicine-id/" + id
      );
      let pagination = response.meta.pagination;
      this.totalRows = pagination.total;

      let a, b;
      b = this.perPage * (this.currentPage - 1) + 1;
      for (a = 0; a < response.data.length; a++) {
        response.data[a].number = b + a;
      }
      this.items_history = response.data;
    },

    async deleteData(id, medicine_id, vendor_id) {
      // Delete Data
      let result = await module.delete(
        "medicine-stocks/" + id + "/" + medicine_id + "/" + vendor_id
      );
      if (result) {
        console.log(result, medicine_id, vendor_id);
        this.pagination();
      }
    },

    async getMedicineHistory() {
      let history = await module.get("medicine-stocks-history/" + 2);
      console.log("history", history);
      // If Data Not Found
      // if (this.history == null) {
      //   // Redirect To List
      //   this.$router.push('/medicine/maintain-stock')
      // }
    },

    // access management
    async setActiveMenu() {
      let access_right_user = window.localStorage.getItem(
        "access_right_display"
      );
      let access_right = JSON.parse(access_right_user);

      let a;
      for (a = 0; a < access_right.length; a++) {
        // console.log("looping")

        if (access_right[a] == "9005") {
          this.manipulateBtn = true;
        }
      }
    },

    // filter
    resetFilter() {
      this.filter = validation.clearForm(this.filter)
      this.filter.start_date = moment().startOf('day').format('YYYY-MM-DD HH:mm:ss')
      this.filter.end_date = moment().endOf('day').format('YYYY-MM-DD HH:mm:ss')

      this.pagination()
    },

    btnPeriodeOnClick(){
      if(this.onShowPeriod == false){
        this.onShowPeriod = true
        this.btnActiveOnClick('period')
      }else{
        this.onShowPeriod = false
        this.btnActiveOnClick('period')
      }
    },

    btnActiveOnClick(type){
      if(type == 'days'){
        // set time
        this.filter.start_date = moment().startOf('day').format('YYYY-MM-DD HH:mm:ss')
        this.filter.end_date = moment().endOf('day').format('YYYY-MM-DD HH:mm:ss')

        // get data
        this.pagination()
        
        // set btn Active
        this.btnActive.days = true
        this.btnActive.month = false
        this.btnActive.lastMonth = false
        this.btnActive.year = false
        this.btnActive.period = false

        // hide period
        this.onShowPeriod = false
      }else if(type == 'month'){
        // set time
        this.filter.start_date = moment().startOf('month').startOf('day').format('YYYY-MM-DD HH:mm:ss')
        this.filter.end_date = moment().endOf('month').endOf('day').format('YYYY-MM-DD HH:mm:ss')

        // get data
        this.pagination()
        
        // set btn Active
        this.btnActive.days = false
        this.btnActive.month = true
        this.btnActive.lastMonth = false
        this.btnActive.year = false
        this.btnActive.period = false

        // hide period
        this.onShowPeriod = false
      }else if(type == 'lastMonth'){
        // set time
        this.filter.start_date = moment().subtract(1,'months').startOf('day').format('YYYY-MM-DD HH:mm:ss')
        this.filter.end_date = moment().subtract(1,'months').endOf('day').format('YYYY-MM-DD HH:mm:ss')

        // get data
        this.pagination()
        
        // set btn Active
        this.btnActive.days = false
        this.btnActive.month = false
        this.btnActive.lastMonth = true
        this.btnActive.year = false
        this.btnActive.period = false

        // hide period
        this.onShowPeriod = false
      }else if(type == 'year'){
        // set time
        this.filter.start_date = moment().startOf('year').startOf('day').format('YYYY-MM-DD HH:mm:ss')
        this.filter.end_date = moment().endOf('year').endOf('day').format('YYYY-MM-DD HH:mm:ss')

        // get data
        this.pagination()
        
        // set btn Active
        this.btnActive.days = false
        this.btnActive.month = false
        this.btnActive.lastMonth = false
        this.btnActive.year = true
        this.btnActive.period = false

        // hide period
        this.onShowPeriod = false
      }else if(type == 'period'){
        // set time
        this.filter.start_date = ""
        this.filter.end_date = ""
        
        // set btn Active
        this.btnActive.days = false
        this.btnActive.month = false
        this.btnActive.lastMonth = false
        this.btnActive.year = false
        this.btnActive.period = true
      }
    }
  },

  mounted() {
    this.$store.dispatch(SET_BREADCRUMB, [
      { title: "Obat", route: "/medicine/list" },
      { title: "Stok Obat" },
    ]);
    this.pagination();
    this.getMedicineHistory();
    this.setActiveMenu();
  },
};
</script>

<style scoped>
.th-list-medicines {
  vertical-align: middle !important;
}

.td-list-medicines {
  vertical-align: middle !important;
}
</style>